import 'core-js/stable'; 
import 'regenerator-runtime/runtime';
// Vue
import Vue from 'vue' 
// axios
import axios from 'axios'
// 系统名称
const { name } = require('../package')
// qiankun state
import { globalStateHandle } from '@/entry/ComVariable'
// 根目录
Vue.prototype.Root_Public_Path = window.location.pathname
// qiankun应用
if (window.__POWERED_BY_QIANKUN__) {
  // 修改 __webpack_public_path__
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
  // 修改 Root_Public_Path
  Vue.prototype.Root_Public_Path = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}
// 去除 尾部 /
Vue.prototype.Root_Public_Path = Vue.prototype.Root_Public_Path.replace(/\/$/,'')
/*******************************Config***************************************/
//加载配置文件
//console.log(Vue.prototype.Root_Public_Path,'Root_Public_PathRoot_Public_PathRoot_Public_Path' )
const loadConfig = (props) => {
  window.realWindow = props.realWindow || window
  axios.get(`${Vue.prototype.Root_Public_Path}/config.json?t='+${new Date().getTime()}`).then(async response => {
    // 全局赋值
    try {
      var presetCssCode= await axios.get(`${Vue.prototype.Root_Public_Path}/VFormDesignerModel/presetCssCode.css?t=+${new Date().getTime()}`).then(res=>res.data)
      Vue.prototype.$presetCssCode = presetCssCode
    } catch {
      Vue.prototype.$presetCssCode=null
    } finally {
      Vue.prototype.$config = response.data
      // 主题
      var theme = localStorage['theme'] || Vue.prototype.$config.sysSetting.theme || 'default'
      // 动态载入的文件
      let loadFile = [import('./entry/MainApp')]
      // 独立运行
      props.__isOwn && loadFile.push(import(`@/assets/css/theme/${theme}.theme.scss`))
      // name
      Vue.prototype.__name = name
      // 加载 Vue 入口文件
      Promise.all(loadFile).then(data=>{
        // 执行渲染
        data[0].default.render(props)
      }).catch(function (error) {
        console.error(loadFile)
        // 错误提示信息，便于子应用排查错误
        document.body.innerHTML = '<h1>' + name + '程序访问失败：' + error.message + '</h1>'   
      })
    }

  })
}

// 主程序加载
if(!window.__POWERED_BY_QIANKUN__){
  loadConfig(Object.assign({},{ __isOwn: true,__name: name }))
}

/*******************************Qiankun**************************************/

// 初创
export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

// 触发qiankun框架加载
export async function mount(props) {
  // log
  console.log('[vue] props from main framework', props);
  // init global
  globalStateHandle.initGlobalState(props,props.initGlobalStateData)
  // 加载配置文件
  loadConfig(Object.assign(props,{ __isOwn: false,__name: name }))
}

// 销毁
export async function unmount() {
  // 更新状态
  console.log('[vue] vue app unmount');
}
